import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from 'antd/lib/typography';
import 'antd/lib/typography/style/css';
import Icon from 'antd/lib/icon';
import 'antd/lib/icon/style/css';
import Radio from 'antd/lib/radio';
import 'antd/lib/radio/style/css';
import Button from 'antd/lib/button';
import 'antd/lib/button/style/css';
import Modal from 'antd/lib/modal';
import 'antd/lib/modal/style/css';
import { BodyType, IntegrationType, Variant } from '../../constants';
import Code from '../common/Code';
import FluidWrapper from '../common/FluidWrapper';
import styles from './ContainerStep.module.scss';

const { Paragraph } = Typography;

const SIZES = ['300x250', '336x280', '728x180', '300x600', 'fluid'];
let fluidExplained = false;

const ContainerStep = ({ config, className }) => {
  const [size, setSize] = useState(parseSize(SIZES[0]));
  const [bodyType, setBodyType] = useState(BodyType.CAR);
  const [variant, setVariant] = useState(Variant[bodyType].default);
  const fluid = size[0] === 500 && size[1] === 250;

  const aParam = `data-a="${config.affiliateId}"`;
  const bParam = `data-b="${config.bannerId}"`;
  const channelParam = config.chan ? `\n  data-chan="${config.chan}"` : '';
  const voucherParam = config.voucher
    ? `\n  data-voucher="${config.voucher}"`
    : '';
  const localeParam = `data-locale="${config.locale}"`;

  const width = fluid ? '100%' : `${size[0]}px`;
  const height = fluid ? '100%' : `${size[1]}px`;
  const sizeCss = `width: ${width}; height: ${height}`;

  useEffect(() => {
    setVariant(Variant[bodyType]['variant-1']);
  }, [bodyType]);

  useEffect(() => {
    window.CVAff.load();

    if (fluid && !fluidExplained) {
      fluidExplained = true;
      setTimeout(() => {
        Modal.info({
          title: 'Fluid size',
          content: (
            <Fragment>
              The banner will fit almost any container size, try it out by
              dragging the <span style={{ color: 'red' }}>red</span> handles.
            </Fragment>
          ),
        });
      }, 200);
    }
  }, [fluid, variant, config]);

  const BannerWrapper = fluid ? FluidWrapper : Fragment;

  return (
    <Fragment>
      <div className={className}>
        <Paragraph>
          <Radio.Group
            value={bodyType}
            size="small"
            onChange={event => setBodyType(event.target.value)}
          >
            <Radio.Button value={BodyType.CAR} className={styles.capitalized}>
              {bodyType}
            </Radio.Button>
          </Radio.Group>
        </Paragraph>
        <Paragraph>
          <Radio.Group
            value={variant}
            size="small"
            onChange={event => setVariant(event.target.value)}
          >
            <Radio.Button
              value={Variant.car['variant-1']}
              className={styles.capitalized}
            >
              {variant}
            </Radio.Button>
          </Radio.Group>
        </Paragraph>
        <Paragraph>
          <Radio.Group
            value={fluid ? 'fluid' : `${size[0]}x${size[1]}`}
            size="small"
            onChange={event => setSize(parseSize(event.target.value))}
          >
            {SIZES.map(size => (
              <Radio.Button key={size} value={size}>
                {size}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Paragraph>
        <Paragraph>
          {config.affiliateId && config.bannerId && (
            <BannerWrapper
              {...fluid && {
                width: size[0],
                height: size[1],
              }}
            >
              <div
                className={styles.banner}
                data-cvaff
                data-locale={config.locale}
                data-a={config.affiliateId}
                data-b={config.bannerId}
                data-chan={config.chan}
                data-voucher={config.voucher}
                data-integration-type={IntegrationType.BANNER}
                style={{ width, height }}
              />
            </BannerWrapper>
          )}
        </Paragraph>
        <Paragraph>
          <Code>
            {`&lt;div
  data-cvaff
  ${localeParam}
  ${aParam}
  ${bParam}${channelParam}${voucherParam}
  data-integration-type="${IntegrationType.BANNER}"
  style="${sizeCss}">
&lt;/div>`}
          </Code>
        </Paragraph>
        <Paragraph type="secondary">
          <Icon type="info-circle" /> You can add more than one banner container
          on a single page.
        </Paragraph>
        <Paragraph>
          The SDK will find all banner containers on the loaded page and replace
          them with actual banners.
        </Paragraph>
        <Paragraph type="warning">
          <Icon type="warning" /> If your site is an SPA or banner containers
          are added asynchronously after the page has been loaded, you should
          manually call <code>CVAff.load()</code> to find and replace all newly
          added banner containers.
        </Paragraph>
        <Paragraph>Need help with the integration?</Paragraph>
        <Button icon="mail" href="mailto:affiliate@carvertical.com">
          Get in touch
        </Button>
      </div>
    </Fragment>
  );
};

function parseSize(size) {
  if (size === 'fluid') {
    return [500, 250];
  }
  return size.split('x').map(value => Number(value));
}

ContainerStep.propTypes = {
  config: PropTypes.shape().isRequired,
  className: PropTypes.string.isRequired,
};

export default ContainerStep;
