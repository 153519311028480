import React, { useState } from 'react';
import Layout from 'antd/lib/layout';
import 'antd/lib/layout/style/css';
import Menu from 'antd/lib/menu';
import 'antd/lib/menu/style/css';
import Logo from './components/common/Logo';
import BannersPage from './components/BannersPage';
import DialogsPage from './components/DialogsPage';
import ButtonPage from './components/ButtonPage';
import { IntegrationType } from './constants';
import styles from './App.module.scss';

const { Header, Content, Footer } = Layout;

const App = () => {
  const [page, setPage] = useState(IntegrationType.BANNER);

  return (
    <Layout className={styles.root}>
      <Header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.logoWrapper}>
            <Logo />
          </div>
          <Menu
            className={styles.menu}
            theme="dark"
            mode="horizontal"
            onClick={event => setPage(event.key)}
            defaultSelectedKeys={[IntegrationType.BANNER]}
          >
            <Menu.Item key={IntegrationType.BANNER}>Banners</Menu.Item>
            <Menu.Item key={IntegrationType.DIALOG}>Dialogs</Menu.Item>
            <Menu.Item key={IntegrationType.BUTTON}>Button</Menu.Item>
          </Menu>
        </div>
      </Header>
      <Content className={styles.body}>
        <div className={styles.content}>
          {
            {
              [IntegrationType.BANNER]: <BannersPage />,
              [IntegrationType.DIALOG]: <DialogsPage />,
              [IntegrationType.BUTTON]: <ButtonPage />,
            }[page]
          }
        </div>
      </Content>
      <Footer className={styles.footer}>
        © {new Date().getFullYear()} carVertical. All rights reserved.
      </Footer>
    </Layout>
  );
};

export default App;
