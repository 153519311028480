import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import Icon from 'antd/lib/icon';
import Radio from 'antd/lib/radio';
import Modal from 'antd/lib/modal';
import { ButtonVariant, IntegrationType } from '../../constants';
import Code from '../common/Code';
import FluidWrapper from '../common/FluidWrapper';
import styles from './ContainerStep.module.scss';

const SIZES = ['250x72', 'fluid'];
const REPORT_URL_PLACEHOLDER = 'XXX';
const FLUID_SIZE = {
  width: 360,
  height: 80,
};

const { Paragraph } = Typography;
let fluidExplained = false;

function parseSize(size) {
  if (size === 'fluid') {
    return [FLUID_SIZE.width, FLUID_SIZE.height];
  }
  return size.split('x').map(value => Number(value));
}

const ContainerStep = ({ config }) => {
  const [size, setSize] = useState(parseSize(SIZES[0]));
  const [variant, setVariant] = useState(ButtonVariant.white);

  const fluid = size[0] === FLUID_SIZE.width && size[1] === FLUID_SIZE.height;

  useEffect(() => {
    window.CVAff.load();

    if (fluid && !fluidExplained) {
      fluidExplained = true;
      setTimeout(() => {
        Modal.info({
          title: 'Fluid size',
          content: (
            <Fragment>
              The button will fit almost any container size, try it out by
              dragging the <span style={{ color: 'red' }}>red</span> handles.
            </Fragment>
          ),
        });
      }, 200);
    }
  }, [fluid, variant, config]);

  const localeParam = `data-locale="${config.locale}"`;
  const reportIdParam = `data-report-id="${REPORT_URL_PLACEHOLDER}"`;
  const variantParam = `data-variant="${variant}"`;

  const width = fluid ? '100%' : `${size[0]}px`;
  const height = fluid ? '100%' : `${size[1]}px`;
  const sizeCss = `width: ${width}; height: ${height}`;

  const ButtonWrapper = fluid ? FluidWrapper : Fragment;

  return (
    <div>
      <Paragraph>
        <Radio.Group
          value={variant}
          size="small"
          onChange={event => setVariant(event.target.value)}
        >
          {Object.values(ButtonVariant).map(buttonVariant => (
            <Radio.Button
              key={buttonVariant}
              value={buttonVariant}
              className={styles.capitalized}
            >
              {buttonVariant}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Paragraph>
      <Paragraph>
        <Radio.Group
          value={fluid ? 'fluid' : `${size[0]}x${size[1]}`}
          size="small"
          onChange={event => setSize(parseSize(event.target.value))}
        >
          {SIZES.map(size => (
            <Radio.Button key={size} value={size}>
              {size}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Paragraph>

      <Paragraph>
        <ButtonWrapper
          {...fluid && {
            width: size[0],
            height: size[1],
          }}
        >
          <div
            data-cvaff
            data-report-id={REPORT_URL_PLACEHOLDER}
            data-locale={config.locale}
            data-variant={variant}
            data-integration-type={IntegrationType.BUTTON}
            style={{ width, height }}
          />
        </ButtonWrapper>
      </Paragraph>
      <Paragraph>
        <Code>
          {`&lt;div
  data-cvaff
  ${localeParam}
  ${reportIdParam}
  ${variantParam}
  data-integration-type="${IntegrationType.BUTTON}"
  style="${sizeCss}">
&lt;/div>`}
        </Code>
      </Paragraph>
      <Paragraph type="warning">
        <Icon type="warning" />
        <code>data-report-id</code> is a required parameter.
      </Paragraph>
      <Paragraph type="secondary">
        <Icon type="info-circle" /> You can add more than one button container
        on a single page.
      </Paragraph>
      <Paragraph>
        The SDK will find all button containers on the loaded page and replace
        them with actual buttons.
      </Paragraph>
      <Paragraph type="warning">
        <Icon type="warning" /> If your site is an SPA or button containers are
        added asynchronously after the page has been loaded, you should manually
        call <code>CVAff.load()</code> to find and replace all newly added
        button containers.
      </Paragraph>
      <Paragraph>Need help with the integration?</Paragraph>
      <Button icon="mail" href="mailto:affiliate@carvertical.com">
        Get in touch
      </Button>
    </div>
  );
};

ContainerStep.propTypes = {
  config: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
};

export default ContainerStep;
