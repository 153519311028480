import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import Form from 'antd/lib/form';
import 'antd/lib/form/style/css';
import Input from 'antd/lib/input';
import 'antd/lib/input/style/css';
import Select from 'antd/lib/select';
import 'antd/lib/select/style/css';
import { LocaleOption } from '../../../constants';
import CountryLabel from '../CountryLabel';
import styles from './ConfigStep.module.scss';

const { Option } = Select;

const OPTIONS = Object.entries(LocaleOption).map(([key, value]) => ({
  value: key,
  Label: () => (
    <CountryLabel
      flag={value.flag}
      name={value.name}
      language={value.language}
    />
  ),
}));

const INPUT_CONFIG = {
  affiliateId: {
    rules: [{ required: true, message: 'Required' }],
    Controller: <Input />,
    label: 'Affiliate ID',
  },
  bannerId: {
    rules: [{ required: true, message: 'Required' }],
    Controller: <Input />,
    label: 'Banner ID',
  },
  locale: {
    rules: [{ required: true, message: 'Required' }],
    Controller: (
      <Select placeholder="Select locale…">
        {OPTIONS.map(({ value, Label }) => (
          <Option key={value} value={value}>
            <Label />
          </Option>
        ))}
      </Select>
    ),
    label: 'Locale',
  },
  chan: {
    Controller: <Input />,
    label: 'Channel',
  },
  voucher: {
    Controller: <Input />,
    label: 'Voucher',
  }
};

const ConfigStep = ({ inputs, className, renderAction, onContinue, form }) => (
  <Form
    layout="vertical"
    onSubmit={event => {
      event.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          onContinue(values);
        }
      });
    }}
  >
    <div className={className}>
      {inputs.map(input => {
        const { Controller, rules, label } = INPUT_CONFIG[input] || {};

        return (
          <Form.Item className={styles.field} label={label} key={input}>
            {form.getFieldDecorator(input, { rules })(Controller)}
          </Form.Item>
        );
      })}
    </div>
    {renderAction({ htmlType: 'submit' })}
  </Form>
);

ConfigStep.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string.isRequired,
  renderAction: PropTypes.func,
  onContinue: PropTypes.func.isRequired,

  // Form.create
  form: PropTypes.shape().isRequired,
};
ConfigStep.propTypes = {
  renderAction: () => null,
};

export default Form.create({
  name: 'config',
  onValuesChange: (props, changedValues, allValues) =>
    (props.onValuesChange || noop)(allValues),
})(ConfigStep);
