import React from 'react';
import 'antd/lib/collapse/style/css';
import 'antd/lib/button/style/css';
import PageHeader from '../common/PageHeader';
import PanelController from '../common/PanelController';
import ContainerStep from './ContainerStep';

const BannersPage = () => (
  <div>
    <PageHeader title="Banners" subTitle="Integrate VIN banners on your site" />
    <PanelController
      configInputs={['affiliateId', 'bannerId', 'locale', 'chan', 'voucher']}
      panels={[
        {
          title:
            'Place the banner container code where you want the banner to appear',
          PanelItem: ContainerStep,
        },
      ]}
    />
  </div>
);

export default BannersPage;
