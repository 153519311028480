import React from 'react';
import PageHeader from '../common/PageHeader';
import PanelController from '../common/PanelController';
import ContainerStep from './ContainerStep';

const DialogsPage = () => (
  <div>
    <PageHeader title="Button" subTitle="Integrate buttons on your site" />
    <PanelController
      configInputs={['locale']}
      panels={[
        {
          title: 'Place button code on your site',
          PanelItem: ContainerStep,
        },
      ]}
    />
  </div>
);

export default DialogsPage;
