import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Collapse from 'antd/lib/collapse';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import Button from 'antd/lib/button';
import ConfigStep from './panels/ConfigStep';
import SdkStep from './panels/SdkStep';
import usePrevious from '../../hooks/usePrevious';
import styles from './PanelController.module.scss';

const { Panel } = Collapse;

const SCROLL_TARGET_OFFSET = 140;

const PanelController = ({ configInputs, panels }) => {
  const [activePanels, setActivePanels] = useState([0]);
  const prevActivePanels = usePrevious(activePanels);
  const lastActivePanel = activePanels[activePanels.length - 1];

  const [config, setConfig] = useState({});
  const hasConfig = !isEmpty(config);

  const [updateAllowed, setUpdateAllowed] = useState(false);
  const prevUpdateAllowed = usePrevious(updateAllowed);
  const setUpdateAllowedDebounced = debounce(setUpdateAllowed, 500);

  const actionRef = useRef(null);
  const scrollTargetRef = useRef(null);

  const scrollToTarget = () =>
    setTimeout(() => {
      if (scrollTargetRef.current) {
        window.scrollTo({
          left: 0,
          top: scrollTargetRef.current.offsetTop - SCROLL_TARGET_OFFSET,
          behavior: 'smooth',
        });
      }
    }, 400);

  useEffect(() => {
    if (prevActivePanels && activePanels.length > prevActivePanels.length) {
      actionRef.current.buttonNode.focus();
      scrollToTarget();
    }
    if (!updateAllowed && prevUpdateAllowed) {
      scrollToTarget();
    }
  });

  const next = currentIndex =>
    setActivePanels(activePanels.concat(currentIndex + 1));

  function createPanelProps(index, title) {
    return {
      key: `${index}`,
      className: styles.panel,
      header: `${index + 1}. ${title}`,
      showArrow: false,
      disabled: index > lastActivePanel,
    };
  }

  function createActionRenderer(index, { disabled, label } = {}) {
    const completed =
      typeof disabled === 'boolean' ? disabled : index < lastActivePanel;
    return props => (
      <div className={styles.action}>
        <Button ref={actionRef} type="primary" disabled={completed} {...props}>
          {label || (completed ? 'Completed' : 'Next')}
        </Button>
      </div>
    );
  }

  return (
    <Collapse activeKey={activePanels.map(index => `${index}`)}>
      {(index => (
        <Panel
          {...createPanelProps(
            index,
            'Enter your affiliate, banner IDs and select target locale',
          )}
        >
          <ConfigStep
            inputs={configInputs}
            className={styles.content}
            renderAction={createActionRenderer(
              index,
              hasConfig && {
                disabled: !updateAllowed,
                label: updateAllowed ? 'Update' : undefined,
              },
            )}
            onContinue={values => {
              setConfig(values);
              if (!hasConfig) {
                next(index);
              } else {
                setUpdateAllowed(false);
              }
            }}
            {...hasConfig && {
              onValuesChange: newConfig =>
                setUpdateAllowedDebounced(!isEqual(newConfig, config)),
            }}
          />
        </Panel>
      ))(0)}
      {(index => (
        <Panel
          {...createPanelProps(
            index,
            'Place the Affiliate SDK code on your site',
          )}
        >
          {index === lastActivePanel && <div ref={scrollTargetRef} />}
          <SdkStep
            className={styles.content}
            renderAction={createActionRenderer(index)}
            onContinue={() => next(index)}
          />
        </Panel>
      ))(1)}
      {panels.map(({ title, PanelItem }, index) => {
        const panelIndex = index + 2;

        return (
          <Panel {...createPanelProps(panelIndex, title)}>
            {panelIndex === lastActivePanel && <div ref={scrollTargetRef} />}
            <PanelItem config={config} className={styles.content} />
          </Panel>
        );
      })}
    </Collapse>
  );
};

PanelController.propTypes = {
  configInputs: PropTypes.arrayOf(PropTypes.string).isRequired,
  panels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    PanelItem: PropTypes.func
  })),
};

PanelController.defaultProps = {
  panels: []
};

export default PanelController;
