import React from 'react';
import PropTypes from 'prop-types';
import Resizable from 're-resizable';
import styles from './FluidWrapper.module.scss';

const FluidWrapper = ({ width, height, children }) => (
  <Resizable
    defaultSize={{ width, height }}
    handleWrapperClass={styles.resizeHandles}
  >
    {children}
  </Resizable>
);

FluidWrapper.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default FluidWrapper;
