const BodyType = {
  CAR: 'car',
};

const { CAR } = BodyType;

const Variant = {
  [CAR]: {
    'variant-1': 'variant-1',
  },
};

const TranslationMap = {
  [CAR]: {
    'variant-1': 'variant-1',
  },
};

const LocaleOption = {
  en: {
    flag: '🇺🇸',
    name: 'United States',
  },
  'es-US': {
    flag: '🇺🇸',
    name: 'United States',
    language: 'Spanish',
  },
  'en-EU': {
    flag: '🇪🇺',
    name: 'Europe',
    language: 'English',
  },
  'en-GB': {
    flag: '🇬🇧',
    name: 'United Kingdom',
  },
  'en-AU': {
    flag: '🇦🇺',
    name: 'Australia',
  },
  lt: {
    flag: '🇱🇹',
    name: 'Lithuania',
  },
  'ru-LT': {
    flag: '🇱🇹',
    name: 'Lithuania',
    language: 'Russian',
  },
  ro: {
    flag: '🇷🇴',
    name: 'Romania',
  },
  hu: {
    flag: '🇭🇺',
    name: 'Hungary',
  },
  pl: {
    flag: '🇵🇱',
    name: 'Poland',
  },
  lv: {
    flag: '🇱🇻',
    name: 'Latvia',
  },
  'ru-LV': {
    flag: '🇱🇻',
    name: 'Latvia',
    language: 'Russian',
  },
  et: {
    flag: '🇪🇪',
    name: 'Estonia',
  },
  'ru-EE': {
    flag: '🇪🇪',
    name: 'Estonia',
    language: 'Russian',
  },
  fr: {
    flag: '🇫🇷',
    name: 'France',
  },
  uk: {
    flag: '🇺🇦',
    name: 'Ukraine',
  },
  'ru-UA': {
    flag: '🇺🇦',
    name: 'Ukraine',
    language: 'Russian',
  },
  cs: {
    flag: '🇨🇿',
    name: 'Czechia',
  },
  hr: {
    flag: '🇭🇷',
    name: 'Croatia',
  },
  sv: {
    flag: '🇸🇪',
    name: 'Sweden',
  },
  bg: {
    flag: '🇧🇬',
    name: 'Bulgaria',
  },
  sk: {
    flag: '🇸🇰',
    name: 'Slovakia',
  },
  sr: {
    flag: '🇷🇸',
    name: 'Serbia',
  },
  fi: {
    flag: '🇫🇮',
    name: 'Finland',
  },
  'sv-FI': {
    flag: '🇫🇮',
    name: 'Finland',
    language: 'Swedish',
  },
  sl: {
    flag: '🇸🇮',
    name: 'Slovenia',
  },
  de: {
    flag: '🇩🇪',
    name: 'Germany',
  },
  'nl-BE': {
    flag: '🇧🇪',
    name: 'Belgium',
    language: 'Dutch',
  },
  'fr-BE': {
    flag: '🇧🇪',
    name: 'Belgium',
    language: 'French',
  },
  it: {
    flag: '🇮🇹',
    name: 'Italy',
  },
  'de-CH': {
    flag: '🇨🇭',
    name: 'Switzerland',
    language: 'German',
  },
  'it-CH': {
    flag: '🇨🇭',
    name: 'Switzerland',
    language: 'Italian',
  },
  'fr-CH': {
    flag: '🇨🇭',
    name: 'Switzerland',
    language: 'French',
  },
  da: {
    flag: '🇩🇰',
    name: 'Denmark',
  },
  es: {
    flag: '🇪🇸',
    name: 'Spain',
  },
  'es-MX': {
    flag: '🇲🇽',
    name: 'Mexico',
  },
};

const IntegrationType = {
  DIALOG: 'dialog',
  BUTTON: 'button',
  BANNER: 'banner',
};

const ButtonVariant = {
  white: 'white',
  yellow: 'yellow',
  black: 'black',
};

const LandingPath = {
  [CAR]: '/landing/v3',
};

export {
  BodyType,
  Variant,
  TranslationMap,
  LocaleOption,
  ButtonVariant,
  IntegrationType,
  LandingPath,
};
