import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from 'antd/lib/typography';
import 'antd/lib/typography/style/css';
import Icon from 'antd/lib/icon';
import 'antd/lib/icon/style/css';
import Code from '../Code';

const { Paragraph, Text } = Typography;

const SdkStep = ({ className, renderAction, onContinue }) => (
  <Fragment>
    <div className={className}>
      <Paragraph>
        <Code>
          {`&lt;script>
  (function(w,d,u,h,s)&#123;
    h=d.getElementsByTagName('head')[0];
    s=d.createElement('script');
    s.async=1;
    s.src=u+'/sdk.js';
    h.appendChild(s);
  })(window,document,'https://aff.carvertical.com');
&lt;/script>`}
        </Code>
      </Paragraph>
      <Text type="warning">
        <Icon type="warning" /> This code should placed on your site only{' '}
        <strong>once</strong>. Ideally, before the closing{' '}
        <code>&lt;/body></code> tag.
      </Text>
    </div>
    {renderAction({ onClick: onContinue })}
  </Fragment>
);

SdkStep.propTypes = {
  className: PropTypes.string.isRequired,
  renderAction: PropTypes.func,
  onContinue: PropTypes.func.isRequired,
};
SdkStep.propTypes = {
  renderAction: () => null,
};

export default SdkStep;
