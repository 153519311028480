import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { default as PageHeaderBase } from 'antd/lib/page-header';
import 'antd/lib/page-header/style/css';
import styles from './PageHeader.module.scss';

const PageHeader = ({ className, ...props }) => (
  <PageHeaderBase className={cx(styles.root, className)} {...props} />
);

PageHeader.propTypes = {
  className: PropTypes.string,
};
PageHeader.defaultProps = {
  className: undefined,
};

export default PageHeader;
