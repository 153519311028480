import React from 'react';
import logoUrl from '../../assets/logo.svg';
import styles from './Logo.module.scss';

const Logo = () => (
  <div className={styles.root}>
    <img className={styles.image} src={logoUrl} alt="carVertical" />
    <span className={styles.text}>Affiliate Tools</span>
  </div>
);

export default Logo;
