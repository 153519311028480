import React from 'react';
import PropTypes from 'prop-types';

const CountryLabel = ({ flag, name, language }) => (
  <span>
    <span role="img" aria-label={`${name} flag`}>
      {flag}
    </span>{' '}
    {name} {language && <small>({language})</small>}
  </span>
);

CountryLabel.propTypes = {
  flag: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  language: PropTypes.string,
};

CountryLabel.defaultProps = {
  language: undefined,
};

export default CountryLabel;
