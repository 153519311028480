import React from 'react';
import PropTypes from 'prop-types';
import dompurify from 'dompurify';
import styles from './Code.module.scss';

const Code = ({ children }) => (
  <div className={styles.root}>
    <pre
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(children),
      }}
    />
  </div>
);

Code.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Code;
