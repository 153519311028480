import React from 'react';
import PageHeader from '../common/PageHeader';
import PanelController from '../common/PanelController';
import ContainerStep from './ContainerStep';

const DialogsPage = () => (
  <div>
    <PageHeader title="Dialogs" subTitle="Integrate VIN dialogs on your site" />
    <PanelController
      configInputs={['affiliateId', 'bannerId', 'locale', 'chan', 'voucher']}
      panels={[
        {
          title: 'Place modal code on your site',
          PanelItem: ContainerStep,
        },
      ]}
    />
  </div>
);

export default DialogsPage;
