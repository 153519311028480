import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash/util';
import { pickBy } from 'lodash/object';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import Icon from 'antd/lib/icon';
import Code from '../common/Code';

const PLACEHOLDER = {
  photoUrl: 'https://api.lorem.space/image/car?w=150&h=150',
  logoUrl: '/dialog/visual/images/logo-placeholder.png',
  vehicleName: 'BMW M3 petrol 2015',
  sellerPhone: '+370 000 00000',
};

const { Paragraph } = Typography;

const ContainerStep = ({ config }) => {
  const aParam = config.affiliateId;
  const bParam = config.bannerId;
  const channelParam = config.chan || '';
  const voucherParam = config.voucher || '';
  const localeParam = config.locale;
  const { sellerPhone, photoUrl, vehicleName, logoUrl } = PLACEHOLDER;

  const params = {
    a: aParam,
    b: bParam,
    chan: channelParam,
    voucher: voucherParam,
    locale: localeParam,
    photoUrl,
    logoUrl,
    vehicleName,
    sellerPhone,
  };

  const openDialog = () => {
    window.CVAff.showDialog(params);
  };

  const formatConfig = () => {
    const cleanedObject = pickBy(params, identity);

    return JSON.stringify(cleanedObject, null, 4)
      .replace(/[{}]/g, '')
      .trim();
  };

  return (
    <div>
      <Paragraph>
        <Paragraph>
          To open the modal, call <code>window.CVAff.showDialog()</code> with the following parameters:
        </Paragraph>
        <Code>
          {`window.CVAff.showDialog({
    ${formatConfig()}
});`}
        </Code>
      </Paragraph>
      <Paragraph type="warning">
        <Icon type="warning" />
        <code>sellerPhone</code> is a required parameter.
      </Paragraph>
      <Paragraph>
        <Button onClick={openDialog}>Open dialog</Button>
      </Paragraph>
      <Paragraph>Need help with the integration?</Paragraph>
      <Button icon="mail" href="mailto:affiliate@carvertical.com">
        Get in touch
      </Button>
    </div>
  );
};

ContainerStep.propTypes = {
  config: PropTypes.shape({
    affiliateId: PropTypes.string,
    bannerId: PropTypes.string,
    chan: PropTypes.string,
    voucher: PropTypes.string,
    locale: PropTypes.string,
  }).isRequired,
};

export default ContainerStep;
